function client(endpoint, { body, params, pathData, ...customConfig } = {}) {
  const token = window.localStorage.getItem('__clinical_operator_token__')
  const headers = { 'content-type': 'application/json' }
  headers['x-token'] = process.env.REACT_APP_API_TOKEN
  headers['user-agent'] = navigator.userAgent
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }
  if (body) {
    config.body = JSON.stringify(body)
  }
  let parameters = ''
  if (params && Object.keys(params).length > 0) {
    parameters = `?${new URLSearchParams(params).toString()}`
  }

  let path = ''
  if (pathData) {
    pathData.forEach(element => {
      path = `${path}/${element}`
    })
  }

  return window
    .fetch(
      `${process.env.REACT_APP_API_URL}/${endpoint}${path}${parameters}`,
      config
    )
    .then(async r => {
      if (r.ok) {
        const contentType = r.headers.get('content-type')
        if (contentType && contentType.indexOf('application/json') !== -1) {
          return r.json()
        }
        if (
          contentType &&
          (contentType.indexOf('application/xml') !== -1 ||
            contentType.indexOf('application/pdf') !== -1 ||
            contentType.indexOf('text/csv') !== -1 ||
            contentType.indexOf('application/vnd.ms-excel') !== -1)
        ) {
          return r.blob()
        }
        return r.json()
      }
      const contentType = r.headers.get('content-type')
      if (contentType && contentType.indexOf('application/json') !== -1) {
        const error = await r.json()
        return Promise.reject(error.error ? error.error : error)
      }
      return Promise.reject(new Error('Unknown Error'))
    })
}

export default client
