import React from 'react'

const RecordContext = React.createContext()

function RecordProvider({ reducer, initialState, children }) {
  return (
    <RecordContext.Provider value={React.useReducer(reducer, initialState)}>
      {children}
    </RecordContext.Provider>
  )
}

function useNextTrackingValues() {
  const context = React.useContext(RecordContext)
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`)
  }
  return context
}

export { RecordProvider, useNextTrackingValues }
