import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import { ToastProvider } from '@covid/ui/core/Toast'
import { AuthProvider } from './auth'
import { UserProvider } from './user'
import { CommanderProvider } from './commander'
import { RecordProvider } from './patientRecord'
import theme from '../theme'

function AppProviders({ children }) {
  const initialState = {
    nextTrackingDate: '',
    nextTrackingHour: '',
  }
  const reducer = (state, action) => {
    return {
      ...state,
      nextTrackingDate: action.nextTrackingDate,
      nextTrackingHour: action.nextTrackingHour,
    }
  }
  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <ToastProvider>
        <AuthProvider>
          <UserProvider>
            <CommanderProvider>
              <RecordProvider initialState={initialState} reducer={reducer}>
                {children}
              </RecordProvider>
            </CommanderProvider>
          </UserProvider>
        </AuthProvider>
      </ToastProvider>
    </ThemeProvider>
  )
}

export default AppProviders
