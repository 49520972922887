import React from 'react'

const UserContext = React.createContext()

function CommanderProvider(props) {
  return (
    <UserContext.Provider
      value={{
        getCommanderParams,
        saveCommanderParams,
        deleteCommanderParams,
        isFromCommander,
      }}
      {...props}
    />
  )
}

function useCommander() {
  const context = React.useContext(UserContext)
  if (context === undefined) {
    throw new Error(`useCommander must be used within a CommanderProvider`)
  }
  return context
}

function saveCommanderParams(patient) {
  const params = new URLSearchParams(window.location.search)
  const listId = params.get('listId')
  const batchId = params.get('batchId')
  if (listId && batchId) {
    const data = JSON.stringify({ listId, batchId })
    window.localStorage.setItem(getLocalStorageKey(patient), data)
  }
}

function deleteCommanderParams(patient) {
  window.localStorage.removeItem(getLocalStorageKey(patient))
}

function getCommanderParams(patient) {
  const data = window.localStorage.getItem(getLocalStorageKey(patient))
  if (data !== null) {
    return JSON.parse(data)
  }
  return null
}

function isFromCommander(patient) {
  return getCommanderParams(patient) !== null
}

function getLocalStorageKey(patient) {
  return `commander-${patient}`
}

export { CommanderProvider, useCommander }
